import { useI18n } from 'vue-i18n';

export const useConstants = () => {
  const { t } = useI18n();

  const incidentDeleteReason = computed(() => [
    { key: 'yes', label: t('incident.yes_it_is_a_drift') },
    { key: 'no', label: t('incident.no_it_is_not_a drift') },
  ]);

  const daysAsOptions = [
    { key: '1', label: t('global.days_list.short.monday') },
    { key: '2', label: t('global.days_list.short.tuesday') },
    { key: '3', label: t('global.days_list.short.wednesday') },
    { key: '4', label: t('global.days_list.short.thursday') },
    { key: '5', label: t('global.days_list.short.friday') },
    { key: '6', label: t('global.days_list.short.saturday') },
    { key: '7', label: t('global.days_list.short.sunday') },
  ];

  const monthsAsOptions = computed(() => {
    return Array.from({ length: 12 }, (_, i) => ({ key: i + 1, label: t(`global.months.${i + 1}`) }));
  });

  const hoursAsOptions = computed(() => {
    return Array.from({ length: 24 }, (_, i) => ({ key: i, label: `${i}` }));
  });

  const minutesAsOptions = computed(() => {
    return Array.from({ length: 12 }, (_, i) => ({ key: i * 5, label: `${i * 5}` }));
  });

  const contractTypeAsOptions = computed(() => [
    {
      label: t('contract.fixed_rate'),
      key: 'fixed',
    },
    {
      label: t('contract.flexible_rate'),
      key: 'flexible',
    },
  ]);

  const priority = [
    {
      key: 'urgent',
      label: t('incidents.priority_urgent'),
    },
    {
      key: 'high',
      label: t('incidents.priority_high'),
    },
    {
      key: 'medium',
      label: t('incidents.priority_medium'),
    },
    {
      key: 'low',
      label: t('incidents.priority_low'),
    },
  ];

  const areasAsOptions = () => {
    return areaStore()
      .getAreas.filter((area: any) => area.meters.length > 0)
      .map((area: any) => {
        return {
          key: area.id,
          label: area.name,
        };
      });
  };

  const metersAsOptions = (areaId: number) => {
    const selectedArea = areaStore().getAreas.find((area: any) => area.id === areaId);

    if (!selectedArea) return [];
    return selectedArea.meters?.map((meter) => ({
      key: meter?.id,
      label: t(`global.energy_type.${meter?.meter_type?.energy_type}`),
    }));
  };

  const alertTypeOptions = (type?: string) => {
    const alertType = alertStore()
      .getAlertAnalyticType.filter((alertType: any) => (type ? alertType.value === type : true))
      .map((alertType: any) => {
        return {
          key: alertType.value,
          label: t(`alerts.type_${alertType.value}`),
        };
      });

    return alertType;
  };

  const userAsOptions = () => {
    return userStore().getUsers.map((user: any) => {
      return {
        key: user.id,
        label: user.first_name + ' ' + user.last_name,
      };
    });
  };

  const energyTypeAsOptions = computed(() => {
    return [
      {
        key: 'Electricity',
        label: t('global.energy_type.Electricity'),
      },
      {
        key: 'Gas',
        label: t('global.energy_type.Gas'),
      },
    ];
  });

  return {
    incidentDeleteReason,
    priority,
    areasAsOptions,
    alertTypeOptions,
    metersAsOptions,
    userAsOptions,
    monthsAsOptions,
    daysAsOptions,
    hoursAsOptions,
    minutesAsOptions,
    energyTypeAsOptions,
    contractTypeAsOptions,
  };
};
